import { Link } from "gatsby";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Container, Row } from "react-bootstrap";
import * as styles from "./MainFooter.module.scss";

const MainFooter = () => {
  return (
    <footer className={styles.root}>
      <Container>
        <Row>
          <Col xl={5}>
            <h4>About</h4>
            <p>
              T Cards Online is built and managed by T Cards Direct; A family
              business with over 50 years of experience manufacturing and
              supplying the most comprehensive range of T Cards and T Card
              Systems.
            </p>
            <h4>Stay updated</h4>
            <ul className={styles.listInline + " list-inline"}>
              <li>
                <Link to='https://www.facebook.com/tcardsonline/'>
                  <FontAwesomeIcon
                    className={styles.icon}
                    icon={["fab", "facebook"]}
                  />{" "}
                  Facebook
                </Link>
              </li>
              <li>
                <Link to='https://www.instagram.com/tcardsonline/'>
                  <FontAwesomeIcon
                    className={styles.icon}
                    icon={["fab", "instagram"]}
                  />{" "}
                  Instagram
                </Link>
              </li>
              <li>
                <Link to='https://twitter.com/tcardsonline'>
                  <FontAwesomeIcon
                    className={styles.icon}
                    icon={["fab", "twitter"]}
                  />{" "}
                  Twitter
                </Link>
              </li>
            </ul>
          </Col>
          <Col xs={6} md={4} xl={3}>
            <h4>Applications</h4>
            <ul className={styles.list + " list-unstyled"}>
              <li>
                <Link to='/automotive'>Automotive</Link>
              </li>
              <li>
                <Link to='/manufacturing'>Manufacturing & Engineering</Link>
              </li>
              <li>
                <Link to='/construction'>Construction & Property Services</Link>
              </li>
              <li>
                <Link to='/accountancy'>Accountancy & Financial Services</Link>
              </li>
            </ul>
          </Col>
          <Col xs={6} md={4} xl>
            <h4>Terms</h4>
            <ul className={styles.list + " list-unstyled"}>
              <li>
                <Link to='/legal/terms-conditions'>Terms & Conditions</Link>
              </li>
              <li>
                <Link to='/legal/terms-of-use'>Terms of Use</Link>
              </li>
              <li>
                <Link to='/legal/acceptable-use'>Acceptable Use</Link>
              </li>
              <li>
                <Link to='/legal/privacy'>Privacy</Link>
              </li>
              <li>
                <Link to='/legal/cookies'>Cookies</Link>
              </li>
            </ul>
          </Col>
          <Col
            itemScope
            itemType='https://schema.org/Organization'
            xs={12}
            md={4}
            xl
          >
            <h4>Contact</h4>
            <address>
              <p
                itemProp='address'
                itemScope
                itemType='https://schema.org/PostalAddress'
              >
                Lillian House,
                <br />
                Clearway, Addington,
                <br />
                West Malling,
                <br />
                Kent,
                <br />
                ME19 5BP
              </p>
            </address>
            <div>
              <a itemProp='telephone' href='tel:+441732871417'>
                +44(0) 1732 871417
              </a>
            </div>
            <div>
              <a itemProp='email' href='mailto:support@tcardsonline.com'>
                support@tcardsonline.com
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default MainFooter;
