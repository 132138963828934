import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import React from "react";
import { Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import Logo from "../../svgs/logo.svg";
import * as styles from "./MainNav.module.scss";

const MainNav = () => {
  return (
    <Navbar className={styles.root} expand='md'>
      <Container>
        <Link className={styles.brand} to='/'>
          <Logo />
        </Link>
        <Navbar.Toggle
          aria-controls='basic-navbar-nav'
          className={styles.toggle}
        />
        <Navbar.Collapse id='basic-navbar-nav' className={styles.main}>
          <Nav className='ml-auto'>
            <NavDropdown
              className={styles.drop}
              title='Applications'
              id='main-nav-dropdown'
            >
              <NavDropdown.Item as={Link} to='/automotive'>
                Automotive
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to='/manufacturing'>
                Manufacturing & Engineering
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to='/construction'>
                Construction & Property Services
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to='/accountancy'>
                Accountancy & Financial Services
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link to='/pricing' as={Link}>
              Pricing
            </Nav.Link>
          </Nav>
          <Nav>
            <Button
              as={OutboundLink}
              className={styles.btn}
              href='https://app.tcardsonline.co.uk/register'
              variant='primary'
              eventCategory='sign_up'
              eventAction='click'
              eventLabel='sign_up'
            >
              Free trial
            </Button>
            <Button
              as={OutboundLink}
              className={styles.btn}
              href='https://app.tcardsonline.co.uk/'
              variant='secondary'
            >
              Login
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default MainNav;
