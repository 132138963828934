import React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import "../../styles/Main.scss";
import MainNav from "../MainNav/MainNav";
import MainFooter from "../MainFooter/MainFooter";
import { Helmet } from "react-helmet";
library.add(fas, far, fal, fab);

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <script type='application/ld+json'>
          {`
        {
            "@context": "http://schema.org",
            "@type": "WebApplication",
            "@id": "t_cards_online",
            "applicationCategory": "BusinessApplication",
            "name": "T Cards Online",
            "operatingSystem": "all",
            "browserRequirements": "Requires Javascript and HTML5 support",
            "url": "https://tcardsonline.co.uk",
            "screenshot": "https://tcardsonline.co.uk/tcards-example.jpg",
            "sameAs" : ["https://www.facebook.com/tcardsonline/",
    "https://www.instagram.com/tcardsonline/",
    "https://twitter.com/tcardsonline"],
            "offers": {
                "@type": "AggregateOffer",
                "offeredBy": {
                  "@type": "Organization",
                  "name":"T Cards Direct"
                },
                "highPrice": "10",
                "lowPrice": "3",
                "priceCurrency": "GBP",
                "priceSpecification": [
                    {
                        "@type": "UnitPriceSpecification",
                        "price": "3",
                        "priceCurrency": "GBP",
                        "name": "Per person per month - 20+ users"
                    },
                    {
                        "@type": "UnitPriceSpecification",
                        "price": "10",
                        "priceCurrency": "GBP",
                        "name": "Per person per month - 1 to 5 users",
                        "referenceQuantity": {
                            "@type": "QuantitativeValue",
                            "value": "1",
                            "unitCode": "MON"
                        }
                    }
                ]
            },
            "creator": {
              "@type":"Organization",
              "@id":"#organization",
              "url":"https://tcardsonline.co.uk",
              "name":"T Cards Direct",
              "logo":{
                "@type":"ImageObject",
                "url":"/icons/icon-512x512.png",
                "width":"512px",
                "height":"512px"
              }
            }
        }`}
        </script>
        <title>T Cards Online</title>
        <meta
          name='description'
          content='The visual planner making work easier & processes better'
        />

        <meta property='og:url' content='https://tcardsonline.co.uk/' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content='T Cards Online' />
        <meta
          property='og:description'
          content='The visual planner making work easier & processes better'
        />
        <meta
          property='og:image'
          content='https://tcardsonline.co.uk/tcards-example.jpg'
        />

        <meta name='twitter:card' content='summary_large_image' />
        <meta property='twitter:domain' content='tcardsonline.co.uk' />
        <meta property='twitter:url' content='https://tcardsonline.co.uk/' />
        <meta name='twitter:title' content='T Cards Online' />
        <meta
          name='twitter:description'
          content='The visual planner making work easier & processes better'
        />
        <meta
          name='twitter:image'
          content='https://tcardsonline.co.uk/tcards-example.jpg'
        />
      </Helmet>
      <MainNav />
      {children}
      <MainFooter />
    </>
  );
};

export default Layout;
